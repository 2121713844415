.container {
    display: flex;
    align-items: center;
    height: 18px;
    min-width: 73px;
    flex-shrink: 0;
    padding: 2px 0 2px 3px;
    border-radius: 10px;
    margin-top: auto;
}
.text {
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.055px;
}
.customText {
    margin: 0 5px 0 3px;
}

.newContainer {
    display: flex;
    align-items: center;
    align-self: flex-end;
    gap: 3px;
}
.unit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 26px;
    border-radius: 7px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    font-variant-numeric: tabular-nums;
}
.colon {
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.1px;
    font-weight: 600;
}

@media (max-width: 768px) {
    .unit {
        width: 20px;
        height: 20px;
        border-radius: 6px;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 0.055px;
    }
    .colon {
        font-size: 13px;
        line-height: 16px;
    }
}
