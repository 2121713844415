.commonShelf {
    width: 100%;
    padding: 16px 0;
    border-radius: 12px;
    background-color: #f0f4f7;
}
.commonShelfNoBorder {
    padding: 0;
}

.commonShelfHeader {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 0 16px 16px 16px;
    -webkit-tap-highlight-color: transparent;
}
.commonShelfNoBorder .commonShelfHeader {
    padding: 0 0 16px;
}

.titleContainer {
    display: flex;
    gap: 8px;
    min-width: 0;
}

.titleLogo {
    height: 44px;
    flex: none;
}
.titleLogo_wide {
    width: unset;
    height: 28px;
}

.titles {
    min-width: 0;
}

.titleBox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    white-space: pre-wrap;
    gap: 4px 6px;
}

.title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    overflow-wrap: break-word;
    font-family: "Ali_ver.18_Lighter-Rounded", Inter, sans-serif;
    font-size: 26px;
    line-height: 28px;
}
.titleWithDefaultFont {
    font-family: "Inter", "Open Sans", sans-serif;
    font-size: 21px;
    font-weight: 600;
}

.subtitle {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    overflow-wrap: break-word;
    font-family: "Inter", "Open Sans", sans-serif;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.13px;
}

.moreButton {
    display: flex;
    align-items: center;
    gap: 2px;
    margin-bottom: auto;
    border-radius: 50px;
    padding: 6px 10px 6px 12px;
}
.moreButtonNoText {
    padding: 9px 15px;
}

/* TODO: что это */
.link {
    display: flex;
    align-items: center;
    color: #547dff;

    font-size: 13px;
    line-height: 16px;
}

.cartButtonOutline {
    display: flex;
    margin-bottom: auto;
    padding: 2px;
    border-radius: 50px;
    cursor: pointer;
}

.cartButtonOutlineText {
    margin: 0 12px;
}

.cartButton {
    display: flex;
    height: 36px;
    padding: 0 12px;
    border-radius: 50px;
}

.cartIcon {
    position: relative;
    color: inherit;
    margin: auto 0 auto 6px;
}

.cartCounter {
    position: absolute;
    top: -7px;
    right: -7px;
    font-size: 9px;
    font-weight: 500;
    font-variant-numeric: tabular-nums;
    line-height: 12px;
    padding: 1px 2px;
    color: inherit;
    border-radius: 6px;
}

.moreSnippetWrapper {
    width: 162px;
    flex: none;
}
.moreSnippet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 10px;
    padding: 12px;
    height: 100%;

    background-color: #fff;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}
.moreSnippetButton {
    display: flex;
    align-items: center;
    gap: 2px;
    border-radius: 50px;
}
.moreSnippetText {
    width: 80px;
}

.moreSnippetOld {
    display: flex;
    height: 100%;
    margin: auto;
    padding: 0 5px;
}
.moreSnippetTextOld:hover {
    color: #547dff !important;
}

.sliderWrapper {
    /* Оказался не нужным здесь, мб не нужен и в основных стилях */
}

.slider.slider {
    overflow-y: hidden;
    padding: 0 16px;
    /**
     * В сниппетах с COMMON_V3_PEAK модом рисуется иконка bookmark, которая на 3px вылезает за пределы сниппета,
     * и чтобы overflow: auto или scroll не обрезал вылезающую часть, padding'ом забираем себе эту область,
     * а margin'ом обратно освобождаем, что оставляет внешний вид нетронутым, влияя только на относительное позиционирование
     */
    padding-top: 3px;
    margin-top: -3px;
    gap: 6px;
    z-index: 2;
}
.sliderSmallGap.slider {
    gap: 4px;
}
.commonShelfNoBorder .slider {
    padding-left: 0;
    padding-right: 0;
}

.cartButtonWrapperMobile {
    display: flex;
    margin: 12px 12px 0 12px;
    flex: 0 0 100%;
}

@media (max-width: 768px) {
    .commonShelf {
        padding: 12px 0;
    }
    .commonShelfNoBorder {
        padding: 0;
    }

    .moreSnippetWrapper {
        width: 124px;
    }

    .commonShelfHeader {
        padding: 0 12px 10px;
    }
    .commonShelfNoBorder .commonShelfHeader {
        padding: 0 0 10px;
    }

    .title {
        font-size: 20px;
        line-height: 22px;
    }
    .titleWithDefaultFont {
        font-size: 17px;
        line-height: 20px;
    }
    .titleLogo {
        height: 38px;
    }
    .titleLogo_wide {
        height: 20px;
        width: unset;
    }

    .subtitle {
        margin-top: 2px;
    }

    .moreButton {
        padding: 4px 6px 4px 8px;
    }
    .moreButtonNoText {
        padding: 5px 8px;
    }
    .link {
        font-size: 11px;
        line-height: 12px;
    }

    .slider.slider {
        padding-left: 12px;
        padding-right: 12px;
    }
    .commonShelfNoBorder .slider {
        padding-left: 0;
        padding-right: 0;
    }
}
