.skeleton {
    height: 341px;
    width: 100%;
    background-color: white;
}

.skeletonHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.skeletonTitles {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 7px;
}

.products {
    display: flex;
    align-items: center;
    gap: 4px;
    overflow: hidden;
    width: 100%;
}

.skeletonSnippet {
    flex: none;
    height: 167px;
    width: 124px;
}

.skeletonBlock {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.05);
}

.line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
    content: "";
    z-index: 100;
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

@media (--snow-tablet) {
    .skeleton {
        height: 347px;
    }

    .skeletonHeader {
        align-items: center;
    }

    .skeletonTitles {
        flex-direction: row;
        gap: 12px;
    }
    .products {
        gap: 6px;
    }

    .skeletonSnippet {
        height: 210px;
        width: 162px;
    }
}
