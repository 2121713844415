.commonShelf {
    background-color: #f0f4f7;
    border-radius: 12px;
    width: 100%;
    padding: 10px 0;
}

.commonShelfHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 0 10px;
    -webkit-tap-highlight-color: transparent;
}

.titleContainer {
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    padding: 3px 6px;
}

.titleLogo {
    height: 28px;
}
.redesignCommonMode .titleLogo {
    width: unset;
    height: 40px;
}

.titles {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2px;
}

.titleBox {
    display: flex;
    flex-wrap: wrap;
    white-space: pre-wrap;
    gap: 4px 6px;
}

.title {
    font-family: "Ali_ver.18_Lighter-Rounded", Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: #18181b;
    background-color: transparent;
    padding: 0;
    margin: 0;
}
.titleWithDefaultFont {
    font-family: "Inter", "Open Sans", sans-serif !important;
}
.redesignCommonMode .title {
    font-size: 28px;
}

.subtitle {
    font-family: "Inter", "Open Sans", sans-serif;
    color: #18181b;
    background-color: transparent;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.13px;
    padding: 0;
    margin: 0;
}

.moreButton {
    display: flex;
    margin: auto 0;
    border-radius: 50px;
    padding: 8px 10px 8px 12px;
}
.moreButtonNoText {
    padding: 8px 10px;
}

.link {
    display: flex;
    align-items: center;
    color: #547dff;
}

.cartButtonOutline {
    display: flex;
    margin: auto 0;
    padding: 2px;
    border-radius: 50px;
    cursor: pointer;
}

.cartButtonOutlineText {
    margin: 0 12px;
}

.cartButton {
    display: flex;
    height: 36px;
    padding: 0 12px;
    border-radius: 50px;
}

.cartIcon {
    position: relative;
    color: inherit;
    margin: auto 0 auto 6px;
}

.cartCounter {
    position: absolute;
    top: -7px;
    right: -7px;
    font-size: 9px;
    font-weight: 500;
    font-variant-numeric: tabular-nums;
    line-height: 12px;
    padding: 1px 2px;
    color: inherit;
    border-radius: 6px;
}

.moreSnippetWrapper {
    width: 155px;
    flex: none;
}

.moreSnippetOld {
    display: flex;
    height: 100%;
    margin: auto;
    padding: 0 5px;
}
.moreSnippetTextOld:hover {
    color: #547dff !important;
}

.sliderWrapper {
    padding-right: 0;
}
.redesignCommonMode .sliderWrapper {
    padding-left: 12px;
}

.slider.slider {
    position: relative;
    gap: 6px;
    overflow-y: hidden;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 2;
}
.redesignCommonMode .slider {
    padding-left: 0;
    padding-right: 10px;
}

.cartButtonWrapperMobile {
    display: flex;
    margin: 12px 12px 0 12px;
    flex: 0 0 100%;
}

@media (--snow-tablet) {
    .commonShelf {
        padding: 14px 0;
    }

    .commonShelfHeader {
        padding: 0 18px;
        margin-bottom: 16px;
    }
    .redesignCommonMode .commonShelfHeader {
        padding: 0 14px;
        margin-bottom: 12px;
    }

    .titleContainer {
        gap: 10px;
        background-color: transparent;
        border-radius: 0;
        padding: 0;
    }
    .redesignCommonMode .titleContainer {
        gap: 4px;
    }

    .titleLogo {
        height: 44px;
    }
    .redesignCommonMode .titleLogo {
        width: unset;
        height: 40px;
    }

    .titles {
        gap: 4px;
    }

    .moreButton {
        padding: 8px 16px;
    }

    .redesignCommonMode .sliderWrapper {
        padding-left: 14px;
        padding-right: 14px;
    }

    .slider.slider {
        padding-left: 14px;
        padding-right: 14px;
    }
    .redesignCommonMode .slider {
        padding-left: 0;
        padding-right: 0;
    }
    .moreSnippetWrapper {
        width: 170px;
    }
    .redesignCommonMode .moreSnippetWrapper {
        width: 160px;
    }
}
